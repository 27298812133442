import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { Box, Stack, Divider, Typography, Paper, IconButton, Tooltip, Drawer, Button, Tabs, Tab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SettingsIcon from "@mui/icons-material/Settings";

import { HttpAdminApi } from "../../interface/admin-api";
import { HttpChannelApi } from "../../interface/channel-api";
import IoTClient from "../../utils/iot/IoTClient";
import { useInterval } from "../../utils/UseInterval";
import { useWindowSize } from "../../utils/useWindowSize";
import { CommonUtils } from "../../utils/common_utils";

import { userState, iotConfig } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import ChannelManageModule from "./ChannelManage/ChannelManageModule";
import LiveStatistic from "./Statistic/LiveStatistic";
import LivePreviewModule from "./Preview/LivePreviewModule";
import LiveChatModule from "./Chat/LiveChatModule";
import LiveProduct from "./Product/LiveProduct";
import NoticeManagementOld from "./ChannelManage/NoticeManagementOld";
import VodManagement from "./Vod/VodManagement";
import LiveEventManagement from "./Event/LiveEventManagement";
import ChangeBroadTitle from "./ChannelManage/ChangeBroadTitle";
import LiveCoupon from "./Coupon/LiveCoupon";

interface propsType {
  userState: userState;
}

const adminApi = new HttpAdminApi();
const channelApi = new HttpChannelApi();
const cUtils = new CommonUtils();

const LiveDetail = (props: propsType) => {
  let navigate = useNavigate();
  const windowSize = useWindowSize();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const iotRef: any = useRef();
  const liveChatPcRef: any = useRef();
  const livePreviewRef: any = useRef();
  const changeBroadTitleRef: any = useRef();

  const { broadSeq } = useParams();

  const [channelInfo, setChannelInfo] = useState<any>({});
  const [poolVod, setPoolVod] = useState(false);

  const [mainContentHeight, setMainContentHeight] = useState(620);
  const [openChannelDetail, setOpenChannelDetail] = useState(false);

  const [pageHeigth, setPageHeight] = useState(0);

  const [tabName, setTabName] = useState("prod");

  useEffect(() => {
    get_channel_info();
    return () => {
      setIsLoading(false);
      setChannelInfo({});
      setPoolVod(false);
      setMainContentHeight(620);
      setOpenChannelDetail(false);
      setPageHeight(0);
      setTabName("prod");
    };
  }, []);

  const get_channel_info = async () => {
    const param: any = {
      command: "get_channel_info",
      broad_seq: broadSeq,
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      const info: any = res.response.channel_info;
      info.channel_pk = `broad_date#${dayjs(info.broad_start_tm).format("YYYYMMDD")}`;
      setChannelInfo(res.response.channel_info);
      if (res.response.channel_info.broad_status === "VOD") {
        setPoolVod(false);
      }
    } else {
      console.error("getChannelInfo 에러 발생 : " + res.response.error_msg);
    }
  };

  // IoT 연결 성공
  const iotConnectEnd = () => {
    console.info("iot Connection Success");
  };

  const iotLost = () => {
    console.info("iot Lost >>> Reload");
    channelInfo?.broad_seq !== undefined && iotRef.current?.connect(); // IoT다시 맺기
  };

  // IoT message recieve callback
  function receiveIoTMobileMessage(topic: any, payLoad: any, clientId: string) {
    if (topic === `live/${channelInfo.broad_seq}/payload`) {
      if (payLoad.topic === "chat/message") {
        // 일반 메세지 도착
        liveChatPcRef.current?.processChannelMessage(payLoad);
      }
    }
  }

  const sendIoTMessageMeta = (metaName: string) => {
    const topic = `live/${channelInfo?.broad_seq}/payload`;
    iotRef.current?.sendIoTMessageMeta(topic, metaName);
  };

  const sendIoTMessage = (payLoad: any) => {
    const topic = `live/${channelInfo?.broad_seq}/payload`;
    iotRef.current?.sendIoTMessage(topic, payLoad);
  };

  const openChangeBroadTitle = () => {
    changeBroadTitleRef.current.open();
  };

  const deleteChannel = async () => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== channelInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    if (channelInfo.broad_status === "START") {
      alert("현재 방송중인 채널입니다. 먼저 방송을 종료 후 다시 시도하세요.");
      return;
    }
    if (!window.confirm("채널을 삭제 하겠습니까?\n한번 삭제한 채널은 복구할 수 없습니다.")) return;

    const param = {
      command: "delete_channel",
      broad_info: {
        pk: channelInfo.channel_pk,
        broad_seq: channelInfo.broad_seq,
      },
      request_user_id: props.userState.id,
    };
    setIsLoading(true);
    const res = await channelApi.post(param);
    if (res.result_code === "200") {
      navigate("/list");
    } else {
      alert(`[ERROR] ${res.result_body.error_msg}`);
    }
    setIsLoading(false);
  };

  const delay: number = 5000; // 5초마다 변경
  useInterval(
    () => {
      get_channel_info();
    },
    poolVod ? delay : null
  );

  useEffect(() => {
    if (windowSize.width > 768) {
      setMainContentHeight(windowSize.height - 270);
    } else {
      setMainContentHeight(windowSize.height - 310);
    }
    setPageHeight(windowSize.height);
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabName(newValue);
  }

  return (
    <>
      <Box sx={{ p: 0, height: "100%" }}>
        <Stack direction={"column"} spacing={0}>
          <Box className={windowSize.width > 768 ? "content-header-root" : "content-header-root-mo"}>
            <Stack direction={"row"} spacing={windowSize.width > 768 ? 2 : 1} sx={{ alignItems: "center" }}>
              {windowSize.width > 768 ? (
                <Typography variant="h5" className={"content-header-title"}>
                  {`방송 진행 관리 : ${
                    channelInfo?.broad_title
                      ? `${channelInfo?.broad_title} - [${dayjs(channelInfo.broad_start_tm).format("YY-MM-DD")}]`
                      : ""
                  }`}
                </Typography>
              ) : (
                <Typography variant="h5" className={"content-header-title-mo"}>
                  {`${
                    channelInfo?.broad_title
                      ? `${channelInfo?.broad_title} - [${dayjs(channelInfo.broad_start_tm).format("YY-MM-DD")}]`
                      : ""
                  }`}
                </Typography>
              )}
              {props.userState.id === channelInfo.host_id && (
                <Tooltip title="방송명 번경">
                  <Box>
                    <IconButton onClick={openChangeBroadTitle}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              )}
            </Stack>
            {windowSize.width > 768 && (
              <Stack direction={"row"} spacing={2} sx={{ alignItems: "center" }}>
                {props.userState.isSuperAdmin && (
                  <Tooltip title="방송삭제">
                    <Box>
                      <IconButton onClick={deleteChannel}>
                        <DeleteForeverIcon color="error" />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </Stack>
            )}
          </Box>
          <Divider />
          <Box className="content-content-root">
            <Stack direction={"column"} spacing={2}>
              {windowSize.width > 768 ? (
                <>
                  {/* 방송 관리 영역 */}
                  <ChannelManageModule
                    userState={props.userState}
                    channelInfo={channelInfo}
                    callback={(payload: any) => {
                      if (payload.command === "loading") {
                        setIsLoading(payload.value);
                      } else if (payload.command === "toast") {
                        toastRef.current?.toast(payload.msg, payload.type, payload.sec, { vertical: "top", horizontal: "center" });
                      } else if (payload.command === "update_stream") {
                        // IOT 전송
                        sendIoTMessageMeta(payload.value);
                        get_channel_info();
                        // make vod
                        if (payload.value === "stop_stream") {
                          setPoolVod(true);
                        }
                      } else if (payload.command === "change_password") {
                        get_channel_info();
                      }
                    }}
                  />
                  {/* 라이브 통계 영역 */}
                  <LiveStatistic userState={props.userState} channelInfo={channelInfo} callback={() => {}} />
                </>
              ) : (
                /* 라이브 통계 영역 */
                <LiveStatistic userState={props.userState} channelInfo={channelInfo} callback={() => {}} />
              )}
              <Box>
                <Stack direction={windowSize.width > 768 ? "row" : "column"} spacing={2}>
                  {windowSize.width > 768 && (
                    /* 방송 미리보기 */
                    <Paper
                      sx={{
                        width: `${(mainContentHeight * 9) / 16}px`,
                        minWidth: `${(mainContentHeight * 9) / 16}px`,
                        height: `${mainContentHeight}px`,
                      }}
                      elevation={5}
                    >
                      <LivePreviewModule ref={livePreviewRef} userState={props.userState} channelInfo={channelInfo} callback={() => {}} />
                    </Paper>
                  )}
                  {/* 채팅 화면 */}
                  <Paper sx={{ width: `100%` }} elevation={5}>
                    {props.userState.id !== "" && !cUtils.isEmptyObj(channelInfo) && (
                      <Box
                        sx={{
                          position: "relative",
                          height: `${mainContentHeight}px`,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <LiveChatModule
                          ref={liveChatPcRef}
                          channelInfo={channelInfo}
                          userState={props.userState}
                          sendIoTMessage={sendIoTMessage}
                          callback={(payload: any) => {
                            if (payload.command === "put_pin_message") {
                              // Iot 전송
                              console.log("payload: ", payload);
                            }
                          }}
                        />
                      </Box>
                    )}
                  </Paper>
                  <Box sx={{ minWidth: "400px", height: `${mainContentHeight}px` }}>
                    <Paper sx={{ height: "100%" }} elevation={5}>
                      <Tabs
                        value={tabName}
                        onChange={handleTabChange}
                        TabIndicatorProps={{
                          style: { backgroundColor: "#000"}
                        }}
                        sx={{
                          height: "48px",
                          ".Mui-selected": {
                            color: "#000000 !important"
                          }
                        }}>
                        <Tab value="prod" label="상품목록" sx={{ fontFamily: "Noto Sans KR" }} />
                        <Tab value="coupon" label="쿠폰" sx={{ fontFamily: "Noto Sans KR" }} />
                      </Tabs>
                      {/* 상품 리스트 */}
                      {tabName === "prod" && (
                        <LiveProduct
                          userState={props.userState}
                          channelInfo={channelInfo}
                          callback={(payload: any) => {
                            if (payload.command === "change_live_product") {
                              sendIoTMessageMeta(payload.command);
                            }
                          }}
                        />
                      )}
                      {tabName === "coupon" && (
                        <LiveCoupon
                          userState={props.userState}
                          channelInfo={channelInfo}
                        />
                      )}
                    </Paper>
                  </Box>
                  {/* 버튼 영역 */}
                  <Box sx={{ minWidth: "100px", height: windowSize.width > 768 ? `${mainContentHeight}px` : "auto" }}>
                    <Paper sx={{ height: "100%" }} elevation={5}>
                      <Stack direction={windowSize.width > 768 ? "column" : "row"} spacing={1} sx={{ p: 1 }}>
                        {windowSize.width < 768 && (
                          <Box
                            sx={{ cursor: "pointer", minWidth: "70px" }}
                            onClick={() => {
                              setOpenChannelDetail(true);
                            }}
                          >
                            <Stack
                              direction={"column"}
                              spacing={0}
                              sx={{ backgroundColor: "#1876d2", color: "#ffffff", borderRadius: "7px", p: 1 }}
                            >
                              <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <SettingsIcon />
                              </Box>
                              <Typography sx={{ fontSize: "14px", textAlign: "center" }}>방송관리</Typography>
                            </Stack>
                          </Box>
                        )}
                        {/* 공지사항관리 */}
                        <NoticeManagementOld
                          userState={props.userState}
                          channelInfo={channelInfo}
                          heigth={pageHeigth}
                          callback={(payload: any) => {
                            if (payload.command === "update_broad_notice") {
                              toastRef.current?.toast("공지사항을 수정했습니다.", "success", 3000, {
                                vertical: "top",
                                horizontal: "center",
                              });
                              sendIoTMessageMeta("reloadChannelInfo");
                              get_channel_info();
                            }
                          }}
                        />
                        {/* VOD관리 */}
                        {channelInfo.broad_status === "VOD" && (
                          <VodManagement
                            userState={props.userState}
                            channelInfo={channelInfo}
                            heigth={windowSize.height}
                            callback={(payload: any) => {
                              if (payload.command === "change_vod") {
                                get_channel_info();
                                livePreviewRef.current.reload();
                              }
                            }}
                          />
                        )}
                        {/* 이벤트관리 */}
                        <LiveEventManagement userState={props.userState} channelInfo={channelInfo} callback={(payload: any) => {}} />
                      </Stack>
                    </Paper>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <ChangeBroadTitle
        ref={changeBroadTitleRef}
        userState={props.userState}
        channelInfo={channelInfo}
        callback={(payload: any) => {
          if (payload.command === "finish_change_title") {
            toastRef.current?.toast("방송명을 변경했습니다.", "success", 4000, { vertical: "top", horizontal: "center" });
            get_channel_info();
          }
        }}
      />
      {channelInfo?.broad_seq !== undefined && (
        <IoTClient
          ref={iotRef}
          broadSeq={channelInfo?.broad_seq}
          iotRegion={iotConfig.region}
          iotIdentityPoolId={iotConfig.identityPollId}
          iotEndPoint={iotConfig.endpoint}
          recieveIoTMessage={receiveIoTMobileMessage}
          iotConnectEnd={iotConnectEnd}
          iotLost={iotLost}
        />
      )}
      {/* 모바일 버전 방송관리 */}
      <Drawer
        anchor={windowSize.width > 768 ? "right" : "top"}
        open={openChannelDetail}
        onClose={() => {
          setOpenChannelDetail(false);
        }}
      >
        <Box sx={{ width: windowSize.width > 768 ? "500px" : "100vw" }}>
          <Box sx={{ p: 2 }}>
            <Stack direction={"column"} spacing={2}>
              {/* 방송 관리 영역 */}
              <ChannelManageModule
                userState={props.userState}
                channelInfo={channelInfo}
                callback={(payload: any) => {
                  if (payload.command === "loading") {
                    setIsLoading(payload.value);
                  } else if (payload.command === "toast") {
                    toastRef.current?.toast(payload.msg, payload.type, payload.sec, { vertical: "top", horizontal: "center" });
                  } else if (payload.command === "update_stream") {
                    // IOT 전송
                    sendIoTMessageMeta(payload.value);
                    get_channel_info();
                    // make vod
                    if (payload.value === "stop_stream") {
                      setPoolVod(true);
                    }
                  } else if (payload.command === "change_password") {
                    get_channel_info();
                  }
                }}
              />
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenChannelDetail(false);
                }}
              >
                닫기
              </Button>
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default LiveDetail;
