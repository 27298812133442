import { useEffect, useState } from "react";
import { Box, Stack, Typography, Paper, CardMedia, Divider, Switch } from "@mui/material";
import { CommonUtils } from "../../../utils/common_utils";
import { userState } from "../../../interface/MainInterface";
import { HttpAdminApi } from "../../../interface/admin-api";
import "./LiveProduct.css";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const cUtils = new CommonUtils();
const adminApi = new HttpAdminApi();

const LiveProduct = (props: propsType) => {
  const [prodList, setProdList] = useState<any>([]);
  const [liveProduct, setLiveProduct] = useState<any>([]);
  useEffect(() => {
    return () => {
      setProdList([]);
      setLiveProduct([]);
    };
  }, []);

  useEffect(() => {
    if (props.channelInfo?.broad_seq !== undefined) {
      get_live_product();
    }
  }, [props.channelInfo]);

  useEffect(() => {
    if (props.channelInfo.broad_prod_list?.length > 0) setProdList(props.channelInfo.broad_prod_list);
  }, [props.channelInfo.broad_prod_list]);

  const get_live_product = async () => {
    const param: any = {
      command: "get_live_product",
      broad_seq: props.channelInfo.broad_seq,
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      setLiveProduct(res.response.live_products);
    }
  };

  const set_live_product = async (list: any) => {
    const param: any = {
      command: "set_live_product",
      broad_seq: props.channelInfo.broad_seq,
      list: list,
    };

    await adminApi.post(param);
    props.callback({ command: "change_live_product" });
    get_live_product();
  };

  return (
    <Box className="admin-prod-list" sx={{ height: "calc(100% - 48px)" }} >
      <Box sx={{ p: 1 }}>
        <Stack direction={"column"} spacing={1}>
          {props.channelInfo.cnslImgPath !== "" &&
            props.channelInfo.cnslImgPath !== undefined &&
            props.channelInfo.cnslImgPath !== null && (
              <Box>
                <Box sx={{ cursor: "pointer !important" }}>
                  <CardMedia
                    component="img"
                    image={props.channelInfo.cnslImgPath}
                    sx={{ height: "70px", borderRadius: "7px", objectFit: "cover" }}
                    onClick={() => {
                      if (
                        props.channelInfo.cnslPageUrl !== "" &&
                        props.channelInfo.cnslPageUrl !== undefined &&
                        props.channelInfo.cnslPageUrl !== null
                      )
                        window.open(props.channelInfo.cnslPageUrl);
                    }}
                  />
                </Box>
                <Box sx={{ pt: 1 }}>
                  <Divider />
                </Box>
              </Box>
            )}
          {props.channelInfo.bnrImgPath !== "" && props.channelInfo.bnrImgPath !== undefined && props.channelInfo.bnrImgPath !== null && (
            <Box>
              <Box sx={{ cursor: "pointer !important" }}>
                <CardMedia
                  component="img"
                  image={props.channelInfo.bnrImgPath}
                  sx={{ height: "70px", borderRadius: "7px", objectFit: "cover" }}
                  onClick={() => {
                    if (
                      props.channelInfo.bnrPageUrl !== "" &&
                      props.channelInfo.bnrPageUrl !== undefined &&
                      props.channelInfo.bnrPageUrl !== null
                    )
                      window.open(props.channelInfo.bnrPageUrl);
                  }}
                />
              </Box>
              <Box sx={{ pt: 1 }}>
                <Divider />
              </Box>
            </Box>
          )}
          {prodList.map((prodInfo: any, index: number) => (
            <Box key={`prod-data-${index}`}>
              <Box sx={{ cursor: "pointer !important" }}>
                <Stack direction={"row"} spacing={1}>
                  <Box>
                    <CardMedia
                      component="img"
                      image={prodInfo.goodsImgPath}
                      sx={{ width: "72px", height: "72px", borderRadius: "7px", objectFit: "contain" }}
                      onClick={() => {
                        window.open(prodInfo.goodsPageUrl);
                      }}
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", width: "100%" }}
                    onClick={() => {
                      window.open(prodInfo.goodsPageUrl);
                    }}
                  >
                    <Stack direction={"column"} spacing={0}>
                      <Typography className={"admin-prod-text-2line"} sx={{ fontWeight: "400" }}>
                        {prodInfo.goodsNm}
                      </Typography>
                      {prodInfo.consumPrc !== undefined && prodInfo.consumPrc !== null ? (
                        <Typography sx={{ fontWeight: "400", fontSize: "11px" }}>
                          <span className="line-through color-lightgray">{`${cUtils.numericComma(prodInfo.consumPrc)}원`}</span>
                        </Typography>
                      ) : (
                        <Box sx={{ height: "16.5px" }}></Box>
                      )}
                      <Stack direction={"row"} spacing={1}>
                        {prodInfo.salePer !== undefined && prodInfo.salePer !== null && (
                          <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                            <span className="color-red">{`${prodInfo.salePer}%`}</span>
                          </Typography>
                        )}
                        <Typography sx={{ fontWeight: "700", fontSize: "14px" }}>
                          <span>{`${cUtils.numericComma(prodInfo.salePrc)}원`}</span>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", minWidth: "70px" }}>
                    <Stack direction={"column"} spacing={0}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          display: "flex",
                          justifyContent: "center",
                          ...(liveProduct.indexOf(prodInfo.goodsSn) > -1 ? { color: "#ff0000" } : { color: "#000000" }),
                        }}
                      >{`NOW`}</Typography>
                      <Switch
                        checked={liveProduct.indexOf(prodInfo.goodsSn) > -1}
                        onChange={() => {
                          let temp: any = [...liveProduct];
                          if (temp.indexOf(prodInfo.goodsSn) > -1) {
                            for (let i = 0; i < temp.length; i++) {
                              if (temp[i] === prodInfo.goodsSn) {
                                temp.splice(i, 1);
                              }
                            }
                          } else {
                            temp.push(prodInfo.goodsSn);
                          }

                          set_live_product(temp);

                          setLiveProduct(temp);
                        }}
                      />
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ pt: 1 }}>
                <Divider />
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default LiveProduct;
