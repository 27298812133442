import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import { v4 as uuidv4 } from "uuid";
import { useWindowSize } from "../../../../utils/useWindowSize";

import { Box, Button, CardMedia, Stack, TextField } from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

import { userState } from "../../../../interface/MainInterface";
import { HttpAdminApi } from "../../../../interface/admin-api";
import { HttpAdminIotApi } from "../../../../interface/admin-iot-rest-api";
import { CommonUtils } from "../../../../utils/common_utils";
import LoadingCircle from "../../../../utils/LoadingCircle";
import Toast from "../../../../utils/Toast";

import LiveEventApplyList from "./LiveEventApplyList";
import LiveEventWinnerList from "./LiveEventWinnerList";
import dayjs from "dayjs";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const adminApi = new HttpAdminApi();
const adminIotApi = new HttpAdminIotApi();
const cUtils = new CommonUtils();

const LiveEventDetailModule = (props: propsType, ref: any) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [gameInfo, setGameInfo] = useState<any>({});

  const applyListRef: any = useRef();

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setGameInfo({});
    };
  }, []);

  const get_game_info = async (_broad_seq: string, _sk: string) => {
    const param: any = {
      command: "get_game_info",
      broad_seq: _broad_seq,
      sk: _sk,
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      setGameInfo(res.response.game_info);
    }
  };

  const sendIoTMessage = async (topic: string, payload: any) => {
    const param: any = {
      topic: topic,
      payload: payload,
    };

    await adminIotApi.send_iot_message(param);
  };

  const sendMessage = async (msg: string, meta: string) => {
    try {
      const msgId = uuidv4();
      const topic = `live/${props.channelInfo.broad_seq}/payload`;
      const chatPayload = {
        command: "send_chat_history",
        topic: "chat/message",
        ChannelArn: props.channelInfo.chime_channel_arn,
        Content: msg,
        CreatedTimestamp: "",
        LastUpdatedTimestamp: "",
        MessageId: msgId,
        Persistence: "PERSISTENT",
        Redacted: false,
        Sender: {
          Arn: props.channelInfo.chime_admin_arn,
          Name: props.userState.name,
        },
        Status: {
          Value: "SENT",
        },
        Type: "STANDARD",
      };
      const iotParam: any = {
        topic: topic,
        payload: chatPayload,
      };
      // IoT 채팅 메세지 전송
      await adminIotApi.send_iot_message(iotParam);
      // 채팅 히스토리 저장
      await adminApi.post(chatPayload);
    } catch (e) {
      console.error("send msg ERROR : ", e);
    }
  };

  // 게임 시작하기
  const start_game = async () => {
    if (!window.confirm("이벤트를 시작하시겠습니까?")) return;

    const param: any = {
      command: "start_game",
      broad_seq: props.channelInfo.broad_seq,
      game_pk: gameInfo.pk,
      game_sk: gameInfo.sk,
      user_id: props.userState.id,
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      // 차임 메세지 전송
      const topic = `live/${props.channelInfo.broad_seq}/payload`;
      const payload: any = {
        topic: "game",
        prot: "start",
        game_seq: gameInfo.sk,
      };
      await sendIoTMessage(topic, payload);
      props.callback({ command: "start_game", info: gameInfo });
    } else if (res.code === "201") {
      window.alert("이미 진행중인 게임이 있습니다. 기존게임을 종료 후 다시 시도하세요.");
    } else {
      console.error(res.response.error_msg);
    }
  };

  // 당첨자 발표
  const sendWinner = async (_drawResult: any, _drawNumberIng: number) => {
    let winner = "";
    for (let i = 0; i < _drawResult.length; i++) {
      winner = winner + cUtils.maskingChar(_drawResult[i].user_id.split("@")[0]);
      if (i !== _drawResult.length - 1) {
        winner = winner + ", ";
      }
    }
    const msg = `${_drawNumberIng}명 추첨 결과\n${winner} 님\n당첨을 축하드립니다!`;
    await sendMessage(msg, "");
  };

  // 추첨
  const draw_game = async (_gameInfo: any, _drawResult: any) => {
    get_game_info(props.channelInfo.broad_seq, _gameInfo.sk);
    const topic = `live/${props.channelInfo.broad_seq}/payload`;
    const payload: any = {
      topic: "game",
      prot: "draw",
      game_seq: _gameInfo.sk,
    };
    await sendIoTMessage(topic, payload);
    await sendWinner(_drawResult, _gameInfo.event_condition.draw_number);
  };

  // 당첨자 재공지
  const resend_winner = async () => {
    if (!window.confirm("당첨자를 재공지 하시겠습니까?")) return;

    const topic = `live/${props.channelInfo.broad_seq}/payload`;
    const payload: any = {
      topic: "game",
      prot: "draw",
      game_seq: gameInfo.sk,
    };
    await sendIoTMessage(topic, payload);
    await sendWinner(gameInfo.draw_result, gameInfo.event_condition.draw_number);
  };

  // 당첨자 다운로드
  const downloadWinnerExcelList = () => {
    if (gameInfo.draw_result.length === 0) return;
    let winnerContent: any = [];
    let mergeCells: any = [];

    const title: any = ["방송명 : ", props.channelInfo.broad_title];
    winnerContent.push(title);
    mergeCells.push({ s: { r: 0, c: 1 }, e: { r: 0, c: 5 } });

    const eventName: any = ["이벤트명 : ", gameInfo.event_condition.title];
    winnerContent.push(eventName);
    mergeCells.push({ s: { r: 1, c: 1 }, e: { r: 1, c: 5 } });

    const eventDtm: any = ["이벤트 일시 : ", dayjs(gameInfo.start_dtm).format("YYYY-MM-DD hh:mm")];
    winnerContent.push(eventDtm);
    mergeCells.push({ s: { r: 2, c: 1 }, e: { r: 2, c: 5 } });

    // 한줄
    winnerContent.push([]);

    let header: any = ["순번", "아이디", "고객번호", "닉네임", "주문번호", "응모시간"];
    winnerContent.push(header);

    // 각 행 순번 만들기
    let winnerIndex = 1;
    // 내용 생성
    for (const winner_info of gameInfo.draw_result) {
      const detail = [
        winnerIndex,
        winner_info["user_id"],
        winner_info["cust_no"],
        winner_info["nick_name"],
        winner_info.apply_info?.order_number ? winner_info.apply_info.order_number : "",
        dayjs(winner_info["apply_dtm"]).format("YYYY-MM-DD HH:mm:ss"),
      ];
      winnerContent = [...winnerContent, detail];
      winnerIndex += 1;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }];

    const winnerSheetName = "당첨자 내역";

    // 파일명
    const fileName = `[${props.channelInfo.broad_title}]-${gameInfo.event_condition.title}(${dayjs(String(gameInfo.start_dtm)).format(
      "YYYY-MM-DD hh:mm"
    )})-당첨자내역.xlsx`;
    cUtils.downloadExcel(winnerContent, colWidth, winnerSheetName, fileName, mergeCells);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    get_game_info,
  }));

  useEffect(() => {
    console.log(gameInfo.event_condition);
  }, [gameInfo]);

  return (
    <>
      {!cUtils.isEmptyObj(gameInfo) && (
        <Box>
          <Box sx={{ mb: 2 }}></Box>
          <Stack direction={windowSize.width > 768 ? "row" : "column"} spacing={windowSize.width > 768 ? 2 : 1}>
            <Box sx={{ minWidth: "30%" }}>
              <Stack
                direction={"column"}
                spacing={windowSize.width > 768 ? 2 : 1}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <Button
                  variant="text"
                  startIcon={
                    gameInfo.flag === "apply" ? (
                      <CelebrationIcon />
                    ) : gameInfo.flag === "authBuy" ? (
                      <ShoppingCartIcon />
                    ) : (
                      <DoNotDisturbIcon />
                    )
                  }
                  sx={{ color: "#000000DE" }}
                >
                  {gameInfo.flag === "apply" ? "추첨이벤트" : gameInfo.flag === "authBuy" ? "구매인증이벤트" : "미분류"}
                </Button>
                <TextField label="이벤트명" value={gameInfo.event_condition.title} variant="outlined" fullWidth size="small" />
                <TextField label="추첨인원" value={gameInfo.event_condition.draw_number} variant="outlined" fullWidth size="small" />
                {gameInfo.event_condition.gift_title && (
                  <TextField label="경품명" value={gameInfo.event_condition.gift_title} variant="outlined" fullWidth size="small" />
                )}
                {gameInfo.event_condition.gift_image_url && (
                  <CardMedia
                    component={"img"}
                    image={gameInfo.event_condition.gift_image_url}
                    sx={{ width: "300px", height: "300px", objectFit: "cover", borderRadius: "50%", border: "1px solid #000000" }}
                  />
                )}

                {gameInfo.event_condition.timeout > 0 && (
                  <TextField label="제한시간(초)" value={gameInfo.event_condition.timeout} variant="outlined" fullWidth size="small" />
                )}

                {gameInfo.game_status === "CREATE" && (
                  <Button
                    variant="contained"
                    onClick={start_game}
                    fullWidth={windowSize.width < 768}
                    disabled={gameInfo.game_status !== "CREATE"}
                  >
                    이벤트 시작
                  </Button>
                )}
                {gameInfo.game_status === "START" && (
                  <Button
                    variant="contained"
                    fullWidth={windowSize.width < 768}
                    onClick={() => {
                      applyListRef.current.draw_game();
                    }}
                    disabled={gameInfo.game_status !== "START"}
                  >
                    이벤트 추첨
                  </Button>
                )}
                {gameInfo.game_status === "END" && (
                  <>
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth={windowSize.width < 768}
                      onClick={() => {
                        resend_winner();
                      }}
                      disabled={gameInfo.game_status !== "END"}
                    >
                      당첨자 재공지
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth={windowSize.width < 768}
                      onClick={() => {
                        downloadWinnerExcelList();
                      }}
                      disabled={gameInfo.game_status !== "END"}
                    >
                      당첨자 다운로드
                    </Button>
                  </>
                )}
              </Stack>
            </Box>
            <Box sx={{ width: "100%" }}>
              {gameInfo.game_status === "START" || gameInfo.game_status === "CREATE" ? (
                <LiveEventApplyList
                  ref={applyListRef}
                  userState={props.userState}
                  channelInfo={props.channelInfo}
                  gameInfo={gameInfo}
                  callback={(payload: any) => {
                    if (payload.command === "draw_game") {
                      toastRef.current?.toast("추첨을 완료했습니다.", "success", 3000, {
                        vertical: "top",
                        horizontal: "center",
                      });
                      draw_game(payload.info, payload.drawResult);
                    }
                  }}
                />
              ) : (
                <LiveEventWinnerList
                  userState={props.userState}
                  channelInfo={props.channelInfo}
                  gameInfo={gameInfo}
                  callback={(payload: any) => {}}
                />
              )}
            </Box>
          </Stack>
        </Box>
      )}
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default forwardRef(LiveEventDetailModule);
