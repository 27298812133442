import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useWindowSize } from "../../../../utils/useWindowSize";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowSelectionModel,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid";
import { Box, Pagination } from "@mui/material";

import { userState } from "../../../../interface/MainInterface";
import LoadingCircle from "../../../../utils/LoadingCircle";
import Toast from "../../../../utils/Toast";

interface propsType {
  userState: userState;
  channelInfo: any;
  gameInfo: any;
  callback: any;
}

const pageSize: number = 5;

const LiveEventWinnerList = (props: propsType) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const gridApi = useGridApiRef();
  // 이벤트 목록
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: pageSize, page: 0 });

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setSelectedRow([]);
      setSelectedPage({ pageSize: pageSize, page: 0 });
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "user_id",
      headerName: "아이디",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "cust_no",
      headerName: "고객번호",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "nick_name",
      headerName: "닉네임",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "order_no",
      headerName: "주문번호",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      renderCell: (param: GridRenderCellParams) => {
        return <span>{param.row.apply_info?.order_number ? param.row.apply_info.order_number : ""}</span>;
      },
    },
    {
      field: "apply_dtm",
      headerName: "응모시간",
      flex: 1,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      renderCell: (param: GridRenderCellParams) => {
        return <span>{dayjs(param.row.apply_dtm).format("YYYY-MM-DD HH:mm:ss")}</span>;
      },
    },
  ];

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={windowSize.width > 768 ? 10 : 0}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ width: "100%", height: 321 }}>
        <DataGrid
          apiRef={gridApi}
          rows={props.gameInfo.draw_result}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageSize,
              },
            },
            sorting: {
              sortModel: [
                {
                  field: "range_num",
                  sort: "asc",
                },
              ],
            },
          }}
          slots={{ pagination: CustomPagination }}
          hideFooterSelectedRowCount
          getRowId={(row) => row.user_id}
          rowSelectionModel={selectedRow}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRow(newRowSelectionModel);
          }}
          paginationModel={selectedPage}
          onPaginationModelChange={(newPagenationModel) => {
            setSelectedPage(newPagenationModel);
          }}
          rowHeight={42}
          onCellClick={(param: GridCellParams) => {
            props.callback({ command: "select_row", info: param.row });
          }}
        />
      </Box>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default LiveEventWinnerList;
