import { useEffect, useRef, useState } from "react";

import { Box, Button, CardMedia, Paper, Stack, TextField, Typography } from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import { userState } from "../../../../interface/MainInterface";
import { HttpAdminApi } from "../../../../interface/admin-api";
import LoadingCircle from "../../../../utils/LoadingCircle";
import Toast from "../../../../utils/Toast";
import PopUploadImageViaApi from "./PopUploadImageViaApi";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const adminApi = new HttpAdminApi();

const CreateEventFormMudule = (props: propsType) => {
  const toastRef: any = useRef();
  const popUploadImageRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [flag, setFlag] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [eventNotice, setEventNotice] = useState("");
  const [giftTitle, setGiftTitle] = useState("");
  const [giftImgPath, setGiftImgPath] = useState("");
  const [drawNumber, setDrawNumber] = useState(0);
  const [timeoutMin, setTimeoutMin] = useState(0);
  const [timeoutSec, setTimeoutSec] = useState(0);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setFlag("");
      setEventTitle("");
      setEventNotice("");
      setGiftTitle("");
      setGiftImgPath("");
      setDrawNumber(0);
      setTimeoutMin(0);
      setTimeoutSec(0);
    };
  }, []);

  const clearCreateFrom = () => {
    // setOpenCreateEventFrom(false);
    setEventTitle("");
    setEventNotice("");
    setGiftTitle("");
    setGiftImgPath("");
    setDrawNumber(0);
    setTimeoutMin(0);
    setTimeoutSec(0);
  };

  // 게임 생성하기
  const create_game = async () => {
    const param: any = {
      command: "create_game",
      broad_seq: props.channelInfo.broad_seq,
      user_id: props.userState.id,
      flag: flag,
      event_condition: {
        title: eventTitle,
        draw_number: drawNumber,
        timeout: timeoutMin * 60 + timeoutSec * 1,
        notice: eventNotice,
        gift_title: giftTitle,
        gift_image_url: giftImgPath,
      },
    };

    setIsLoading(true);
    const res = await adminApi.post(param);
    if (res.code === "200") {
      clearCreateFrom();
      props.callback({ command: "finish_create_evnet" });
    } else {
      console.error("create_game : ", res.response.error_msg);
      toastRef.current?.toast("이벤트 생성에 실패했습니다.", "error", 4000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  };

  // 경품 이미지 업로드
  const upload_gift_image = async () => {
    popUploadImageRef.current.open();
  };

  return (
    <>
      <Paper elevation={5}>
        <Box sx={{ p: 1 }}>
          <Stack direction={"column"} spacing={2}>
            {/* 이벤트 종류 */}
            <Box>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <Button
                    variant={flag === "authBuy" ? "contained" : "outlined"}
                    startIcon={<ShoppingCartIcon />}
                    onClick={() => {
                      setFlag(flag === "authBuy" ? "" : "authBuy");
                    }}
                  >
                    구매인증이벤트
                  </Button>
                </Box>
                {/* <Box>
                  <Button
                    variant={flag === "apply" ? "contained" : "outlined"}
                    startIcon={<CelebrationIcon />}
                    onClick={() => {
                      setFlag(flag === "apply" ? "" : "apply");
                    }}
                  >
                    추첨이벤트
                  </Button>
                </Box> */}
              </Stack>
            </Box>
            {flag === "authBuy" && (
              <Box>
                <Stack direction={"column"} spacing={2}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>구매인증한 사람 중 n명을 추첨하는 이벤트입니다.</Typography>
                  <TextField
                    label="이벤트명"
                    id="eventTitle"
                    value={eventTitle}
                    placeholder="이벤트명을 입력하세요."
                    variant="outlined"
                    autoFocus
                    fullWidth
                    required
                    size="small"
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setEventTitle(e.target.value);
                    }}
                  />
                  <Stack direction={"row"} spacing={2}>
                    <TextField
                      label="경품명"
                      id="giftTitle"
                      value={giftTitle}
                      placeholder="경품명을 입력하세요."
                      variant="outlined"
                      autoFocus
                      fullWidth
                      required
                      size="small"
                      type="search"
                      autoComplete="off"
                      inputProps={{ enterKeyHint: "Enter" }}
                      onChange={(e) => {
                        setGiftTitle(e.target.value);
                      }}
                    />
                    <TextField
                      label="추첨인원"
                      value={drawNumber}
                      variant="outlined"
                      type="number"
                      size="small"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setDrawNumber(e.target.value as unknown as number);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "" || e.target.value === undefined || e.target.value === null) setTimeoutSec(0);
                      }}
                    />
                  </Stack>
                  <Stack direction={"row"} spacing={2}>
                    <Stack direction={"column"} spacing={2} sx={{ width: "300px" }}>
                      <CardMedia
                        component={"img"}
                        image={giftImgPath}
                        sx={{ width: "300px", height: "300px", objectFit: "cover", borderRadius: "50%" }}
                      />
                      <Button variant="contained" onClick={upload_gift_image}>
                        {"이미지 업로드"}
                      </Button>
                    </Stack>
                    <TextField
                      label="이벤트 공지사항"
                      id="eventNotice"
                      value={eventNotice}
                      placeholder="이벤트 공지사항을 입력하세요."
                      variant="outlined"
                      autoFocus
                      fullWidth
                      required
                      multiline
                      rows={10}
                      size="small"
                      type="search"
                      autoComplete="off"
                      inputProps={{ enterKeyHint: "Enter" }}
                      onChange={(e) => {
                        setEventNotice(e.target.value);
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            {flag === "apply" && (
              <Box>
                <Stack direction={"column"} spacing={2}>
                  <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>참여한 사람 중 n명을 추첨하는 이벤트입니다.</Typography>
                  <TextField
                    label="이벤트명"
                    id="eventTitle"
                    value={eventTitle}
                    placeholder="이벤트명을 입력하세요."
                    variant="outlined"
                    autoFocus
                    fullWidth
                    required
                    size="small"
                    type="search"
                    autoComplete="off"
                    inputProps={{ enterKeyHint: "Enter" }}
                    onChange={(e) => {
                      setEventTitle(e.target.value);
                    }}
                  />
                  <TextField
                    label="추첨인원"
                    value={drawNumber}
                    variant="outlined"
                    type="number"
                    size="small"
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) => {
                      setDrawNumber(e.target.value as unknown as number);
                    }}
                    onBlur={(e) => {
                      if (e.target.value === "" || e.target.value === undefined || e.target.value === null) setTimeoutSec(0);
                    }}
                  />
                  <Stack sx={{ width: "100%" }} direction="row" spacing={2}>
                    <TextField
                      label="제한시간(분)"
                      value={timeoutMin}
                      variant="outlined"
                      type="number"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        setTimeoutMin(e.target.value as unknown as number);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "" || e.target.value === undefined || e.target.value === null) setTimeoutSec(0);
                      }}
                    />
                    <TextField
                      label="제한시간(초)"
                      value={timeoutSec}
                      variant="outlined"
                      type="number"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        setTimeoutSec(e.target.value as unknown as number);
                      }}
                      onBlur={(e) => {
                        if (e.target.value === "" || e.target.value === undefined || e.target.value === null) setTimeoutSec(0);
                      }}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <Button variant="contained" color="primary" onClick={create_game} disabled={eventTitle === "" || drawNumber === 0}>
                    생성하기
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      props.callback({ command: "close_form" });
                    }}
                  >
                    닫기
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Paper>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
      <PopUploadImageViaApi
        ref={popUploadImageRef}
        userState={props.userState}
        callback={(payload: any) => {
          if (payload.command === "upload_finish") {
            setGiftImgPath(payload.key);
          }
        }}
      />
    </>
  );
};

export default CreateEventFormMudule;
