import { useEffect, useState, useRef } from "react";
import { useWindowSize } from "../../../utils/useWindowSize";

import { Box, Stack, Button, Typography, Drawer } from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";

import { userState } from "../../../interface/MainInterface";
import LoadingCircle from "../../../utils/LoadingCircle";
import Toast from "../../../utils/Toast";

import CreateEventFormMudule from "./LiveEventModules/CreateEventFormMudule";
import LiveEventListModule from "./LiveEventModules/LiveEventListModule";
import LiveEventDetailModule from "./LiveEventModules/LiveEventDetailModule";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const LiveEventManagement = (props: propsType) => {
  const windowSize = useWindowSize();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [openCreateEventForm, setOpenCreateEventFrom] = useState(false);

  const liveEventListRef: any = useRef();
  const liveEventDetailRef: any = useRef();

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setOpen(false);
      setOpenCreateEventFrom(false);
    };
  }, []);

  return (
    <>
      <Box
        sx={{ cursor: "pointer", minWidth: "70px" }}
        onClick={() => {
          if (props.userState.isSuperAdmin) setOpen(true);
          else window.alert("준비중");
        }}
      >
        <Stack
          direction={"column"}
          spacing={0}
          sx={{ backgroundColor: props.userState.isSuperAdmin ? "#1876d2" : "#a8a8a8", color: "#ffffff", borderRadius: "7px", p: 1 }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <CelebrationIcon />
          </Box>
          <Typography sx={{ fontSize: "14px", textAlign: "center" }}>이벤트</Typography>
        </Stack>
      </Box>
      <Drawer
        anchor={windowSize.width > 768 ? "right" : "top"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={{ width: windowSize.width > 768 ? "78vw" : "100%" }}>
          <Box sx={{ p: 2 }}>
            <Stack direction={"column"} spacing={2}>
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>라이브 이벤트 관리</Typography>
              <Box>
                <Stack direction={"column"} spacing={1}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction={"row"} spacing={2}>
                      <Box>
                        <Button
                          variant="contained"
                          onClick={() => {
                            liveEventListRef.current.get_game_list();
                          }}
                        >
                          조회
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setOpenCreateEventFrom(true);
                          }}
                        >
                          이벤트 생성
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          닫기
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                  {openCreateEventForm && (
                    //이벤트 생성 폼
                    <CreateEventFormMudule
                      userState={props.userState}
                      channelInfo={props.channelInfo}
                      callback={(payload: any) => {
                        if (payload.command === "close_form") setOpenCreateEventFrom(false);
                        else if (payload.command === "finish_create_evnet") {
                          toastRef.current?.toast("이벤트를 생성했습니다.", "success", 4000, { vertical: "top", horizontal: "center" });
                          liveEventListRef.current.get_game_list();
                          setOpenCreateEventFrom(false);
                        }
                      }}
                    />
                  )}
                  {/* 이벤트 리스트 */}
                  <LiveEventListModule
                    ref={liveEventListRef}
                    userState={props.userState}
                    channelInfo={props.channelInfo}
                    callback={(payload: any) => {
                      if (payload.command === "select_row") {
                        liveEventDetailRef.current.get_game_info(props.channelInfo.broad_seq, payload.info.sk);
                      }
                    }}
                  />
                  {/* 이벤트 상세보기 */}
                  <LiveEventDetailModule
                    ref={liveEventDetailRef}
                    userState={props.userState}
                    channelInfo={props.channelInfo}
                    callback={(payload: any) => {
                      if (payload.command === "start_game") {
                        liveEventListRef.current.get_game_list();
                        liveEventDetailRef.current.get_game_info(props.channelInfo.broad_seq, payload.info.sk);
                      }
                    }}
                  />
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Drawer>
      <LoadingCircle loading={isLoading} />
      <Toast ref={toastRef} />
    </>
  );
};

export default LiveEventManagement;
