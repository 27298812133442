import { Box, CardMedia, Divider, Stack } from "@mui/material";
import { userState } from "../../../interface/MainInterface";

import styles from "./LiveCoupon.module.css"
import { useEffect, useState } from "react";
import { CommonUtils } from "../../../utils/common_utils";
import dayjs from "dayjs";

interface propsType {
  userState: userState;
  channelInfo: any;
}

type CouponType = {
  cpnSn: string // 쿠폰번호
  cpnNm: string // 쿠폰명
  cpnTypeCd: string // 쿠폰 유형 코드 (String) 10 : 상품할인 / 20 : 장바구니 할인 / 30: 배송비 할인
  cpnStCd: string // 쿠폰 상태 코드 (String) 20 :사용 / 30 :중지 / 40:만료
  cpnAplyMethCd: string // 쿠폰 적용 방법 코드  10 : 정액(원) / 20 : 정률(%)
  cpnAplyVal: number // 쿠폰 적용 값
  cpnTgtCd: string // 쿠폰 적용 대상 (String) 10 : 상품 / 20: 카테고리 / 30:브랜드 / 40:함께 사면 할인 / 00 : 전체
  minPurAmt: number // 최소 구매 금액
  maxDscntAmt: number // 최대 할인 금액
  mbrMaxIssuQty: number // 회원 최대 발급 수량
  mbrCpnDnldCnt?: number // id당 다운받은 건 수 
  usePsblTermStrtDtime: string // 쿠폰 사용 시작 일시
  usePsblTermFnhDtime: string // 쿠폰 사용 종료 일시
  cpnUsePsblTermTypeCd: string // 쿠폰 사용 기간 타입 ("10" 이면 cpnIssuDtStdDds 사용, 그 외의 경우 usePsblTermFnhDtime 사용)
  cpnIssuDtStdDds: number // 쿠폰 발급 일자 기준 일수 (number)
}

const cUtils = new CommonUtils();

export default function LiveCoupon({ userState, channelInfo }: propsType ) {
  const [couponList, setCouponList] = useState<CouponType[]>([]);

  useEffect(() => {
    if(channelInfo.broad_cpn_list.length > 0)
      setCouponList(channelInfo.broad_cpn_list);

  }, [channelInfo.broad_cpn_list])

  return (
    <Box className={styles.adminCouponList}>
      <Stack direction="column">
        {couponList.map((coupon: CouponType, index: number) => (
          <Box key={`coupon-data${index}`}>
            <Box className={styles.cpnItemBox}>
              <Stack direction="row">
                <Box className={styles.cpnImgBox}>
                  <img src="/images/icon-cpn-list.png" alt="" />
                  <Stack className={styles.cpnImgTextStack} direction="column">
                    <span className={styles.cpnNormalText}>{coupon.cpnTgtCd === "10" ? "상품할인" : coupon.cpnTgtCd === "20" ? "장바구니 할인" : "배송비 할인"}</span>
                    <span className={styles.cpnBoldText} style={{ fontSize: "18px", lineHeight: "18ox"}}>
                      {cUtils.numericComma(coupon.cpnAplyVal)}
                      <span style={{ fontSize: "14px"}}>
                        {coupon.cpnAplyMethCd === "10" ? "원" : "%"}
                      </span>
                    </span>
                    <span className={styles.cpnSmallText}style={{ marginTop: "5px"}}>{`${coupon.mbrMaxIssuQty}`}개 발급 가능</span>
                  </Stack>
                </Box>
                <Box className={styles.cpnDescBox}>
                  <Stack direction="column" className={styles.cpnDescStack}>
                    <span className={styles.cpnBoldText}>{coupon.cpnNm}</span>
                    <span className={styles.cpnNormalText} style={{color: "gray"}}>{coupon.cpnAplyMethCd === "10" ? `최소 구매 금액 ${cUtils.numericComma(coupon.minPurAmt)}원` : `최대 할인 금액 ${cUtils.numericComma(coupon.maxDscntAmt)}원`}</span>
                    <span className={styles.cpnNormalText} style={{color: "gray"}}>{coupon.cpnUsePsblTermTypeCd === "10" ? `다운로드 후 ${coupon.cpnIssuDtStdDds}일 까지 사용 가능` : `${dayjs(coupon.usePsblTermFnhDtime).format("YYYY-MM-DD HH:mm")} 까지 사용 가능`}</span>
                  </Stack>
                </Box>
              </Stack>
              <Divider sx={{ opacity: 0.6, mt: 1.5 }} />
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  )
}