import { useEffect, useRef } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useWindowSize } from "../utils/useWindowSize";

import Box from "@mui/material/Box";

import MainPage from "../page/mainPage/mainPage";
import SignUp from "../page/signUp/SignUp";
import SignIn from "../page/signIn/SignIn";
import Settings from "../page/account/Settings";
import MngUser from "../page/mngUser/MngUser";
import LiveList from "../page/mngLive/LiveList";
import LiveDetail from "../page/mngLive/LiveDetail";
import ChannelStatistic from "../page/mngLive/ChannelStatistic";
import TotalStatistic from "../page/mngLive/TotalStatistic";
import ErrorMonitor from "../page/errorMonitor/ErrorMonitor";
import Test from "../page/test/Test";

import { userState } from "../interface/MainInterface";
import * as AuthMain from "../auth/AuthMain";
import Toast from "../utils/Toast";
import Guide from "../page/guide/Guide";

interface propsType {
  userState: userState;
  drawerWidth: number;
  setSelectedMenu: any;
  winSize: string;
  getLeftCount: any;
  searchResult: any;
  setSearchResult: any;
}

const MainConteiner = (props: propsType) => {
  const windowSize = useWindowSize();
  const location = useLocation();
  const toastRef: any = useRef();

  useEffect(() => {
    const sessionCheck = async () => {
      try {
        if (!(await AuthMain.checkAuthenticated())) {
          moveHome();
        } else {
          props.setSelectedMenu(`/${location.pathname.split("/")[1]}`);
        }
      } catch (error) {
        moveHome();
      }
    };

    const moveHome = () => {
      toastRef.current?.toast("세션이 종료되었습니다.\n로그인 화면으로 이동합니다.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      setTimeout(() => {
        window.location.href = "/signin";
      }, 3000);
    };

    const pathName = `/${location.pathname.split("/")[1]}`;
    if (pathName === "/" || pathName === "/signin" || pathName === "/signup" || pathName === "/guide") {
      props.setSelectedMenu(pathName);
    } else {
      sessionCheck();
    }
    document.title = "리바트라이브 어드민";
  }, [location, props]);

  return (
    <Box sx={{ ml: `${props.drawerWidth}px`, transition: "margin 0.5s", height: windowSize.width > 768 ? "calc(100vh - 44px)" : "auto" }}>
      <Routes>
        <Route path="/" element={<MainPage userState={props.userState} />} />
        {/* <Route path="/signup" element={<SignUp userState={props.userState} />} /> */}
        <Route path="/signin" element={<SignIn userState={props.userState} />} />
        <Route path="/setting" element={<Settings userState={props.userState} />} />
        <Route path="/list" element={<LiveList userState={props.userState} />} />
        <Route path="/live/:broadSeq" element={<LiveDetail userState={props.userState} />} />
        <Route path="/statistic/:broadSeq" element={<ChannelStatistic userState={props.userState} />} />
        <Route path="/totalStatistic" element={<TotalStatistic userState={props.userState} />} />
        <Route path="/guide" element={<Guide />} />
        {props.userState.isAdmin && (
          <>
            <Route path="/mngUser" element={<MngUser userState={props.userState} />} />
            <Route path="/errorMonitor" element={<ErrorMonitor userState={props.userState} />} />
            <Route path="/test" element={<Test />} />
          </>
        )}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      <Toast ref={toastRef} />
    </Box>
  );
};

export default MainConteiner;
