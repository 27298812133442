import { Box } from "@mui/material";

const Guide = () => {
  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
        <iframe src="/guide/guide.html" width="100%" height="100%" style={{ border: 0 }} />
    </Box>
  )
}

export default Guide;