import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { userState } from "../../../interface/MainInterface";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const LivePreviewModule = (props: propsType, ref: any) => {
  const [frameUrl, setFrameUrl] = useState("");

  useEffect(() => {
    if (props.channelInfo.broad_seq !== undefined) {
      let streamUrl: string = "";
      streamUrl = `${process.env.REACT_APP_PLAYER_DOMAIN}/stream/${props.channelInfo.broad_seq}?userId=${props.userState.id}&custNo=&livartBroadSeq=1`;
      setFrameUrl(streamUrl);
    }
    return () => {
      setFrameUrl("");
    };
  }, [props.channelInfo.broad_seq]);

  const reload = () => {
    setFrameUrl("");
    setTimeout(() => {
      let streamUrl: string = "";
      streamUrl = `${process.env.REACT_APP_PLAYER_DOMAIN}/stream/${props.channelInfo.broad_seq}?userId=${props.userState.id}&custNo=&livartBroadSeq=1`;
      setFrameUrl(streamUrl);
    }, 200);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    reload,
  }));

  return (
    <>
      <div className="frame-root">
        {props.channelInfo.broad_seq !== undefined && frameUrl !== "" && (
          <iframe id={"test"} src={frameUrl} width={"100%"} height={"100%"} frameBorder={0} scrolling="no"></iframe>
        )}
      </div>
    </>
  );
};

export default forwardRef(LivePreviewModule);
