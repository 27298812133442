import { useEffect, useState, useRef } from "react";
import { Box, Stack, Button, ButtonGroup, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Pagination } from "@mui/material";
import { useWindowSize } from "../../../utils/useWindowSize";

import { HttpChannelApi } from "../../../interface/channel-api";
import { HttpAdminApi } from "../../../interface/admin-api";
import { CommonUtils } from "../../../utils/common_utils";

import { userState } from "../../../interface/MainInterface";

import { useInterval } from "../../../utils/UseInterval";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  useGridApiRef,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  GridRowSelectionModel,
  GridPaginationModel,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";

import Toast from "../../../utils/Toast";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}
const pageSize: number = 100;

const channelApi = new HttpChannelApi();
const adminApi = new HttpAdminApi();
const cUtils = new CommonUtils();

const LiveStatistic = (props: propsType) => {
  const windowSize = useWindowSize();
  const gridApi = useGridApiRef();
  const toastRef: any = useRef();

  const [statisticsList, setStatisticsList] = useState<any>([]);

  const [concurentView, setConcurentView] = useState(0);
  const [totalLike, setTotalLike] = useState(0);
  const [totalLiveLike, setTotalLiveLike] = useState(0);
  const [totalView, setTotalView] = useState(0);
  const [totalLiveView, setTotalLiveView] = useState(0);
  const [totalViewTime, setTotalViewTime] = useState(0);
  const [prodClickList, setProdClickList] = useState<any>([]);
  const [broadTime, setBroadTime] = useState("00:00:00");

  const [concurentViewDataRows, setConcurentViewDataRows] = useState<any>([]);
  const [enterDataRows, setEnterDataRows] = useState<any>([]);
  const [likeDataRows, setLikeDataRows] = useState<any>([]);
  const [prodDataRows, setProdDataRows] = useState<any>([]);
  const [deliveredSecDataRows, setDeliveredSecDataRows] = useState<any>([]);

  // 리바트 API
  const [livartOrderPrice, setLivartOrderPrice] = useState(0);
  const [livartOrderCount, setLivartOrderCount] = useState(0);
  const [livartCancelCount, setLivartCancelCount] = useState(0);
  const [livartCnsCount, setLivartCnsCount] = useState(0);

  const [livartOrderList, setLivartOrderList] = useState<any>([]);
  const [livartCnsList, setLivartCnsList] = useState<any>([]);

  const [openOrderDetail, setOpenOrderDetail] = useState(false);

  const gridHeight = 608;
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel>([]);
  const [selectedPage, setSelectedPage] = useState<GridPaginationModel>({ pageSize: pageSize, page: 0 });

  useEffect(() => {
    return () => {
      setConcurentView(0);
      setTotalLike(0);
      setTotalLiveLike(0);
      setTotalView(0);
      setTotalLiveView(0);
      setTotalViewTime(0);
      setProdClickList(0);
      setBroadTime("00:00:00");
      setConcurentViewDataRows([]);
      setEnterDataRows([]);
      setLikeDataRows([]);
      setProdDataRows([]);
      setDeliveredSecDataRows([]);
      setLivartOrderPrice(0);
      setLivartOrderCount(0);
      setLivartCancelCount(0);
      setLivartCnsCount(0);
      setLivartOrderList([]);
      setLivartCnsList([]);
      setOpenOrderDetail(false);
      setSelectedRow([]);
      setSelectedPage({ pageSize: pageSize, page: 0 });
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "accpDtime",
      headerName: "주문일시",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{dayjs(param.row.accpDtime).format("YYYY-MM-DD HH:mm:ss")}</span>
          </>
        );
      },
    },

    {
      field: "ordNo",
      headerName: "주문번호",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "goodsSn",
      headerName: "상품번호",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "goodsNm",
      headerName: "상품명",
      flex: 1,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "status",
      headerName: "상태",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{param.row.status === "O" ? "주문" : "취소"}</span>
          </>
        );
      },
    },
    {
      field: "rmndQty",
      headerName: "주문수량",
      width: 100,
      headerAlign: "center",
      align: "right",
    },
    {
      field: "payAmt",
      headerName: "주문금액",
      width: 160,
      headerAlign: "center",
      align: "right",
      renderCell: (param: GridRenderCellParams) => {
        return (
          <>
            <span>{cUtils.numericComma(param.row.payAmt)}</span>
          </>
        );
      },
    },
  ];

  function CustomPagination() {
    const page = useGridSelector(gridApi, gridPageSelector);
    const pageCount = useGridSelector(gridApi, gridPageCountSelector);
    return (
      <Box className="tenants-list-pagenation">
        <Pagination
          color="primary"
          shape="rounded"
          count={pageCount === 0 ? 1 : pageCount}
          page={page + 1}
          siblingCount={10}
          onChange={(e, v) => gridApi.current.setPage(v - 1)}
        ></Pagination>
      </Box>
    );
  }

  useEffect(() => {
    if (!cUtils.isEmptyObj(props.channelInfo)) get_statistics();
  }, [props.channelInfo]);

  const get_statistics = () => {
    get_statistics_data();
    get_prod_click();
    get_livart_order_list();
    get_livart_cns_list();
  };

  // 통계 데이터 조회
  const get_statistics_data = async () => {
    const param = {
      command: "get_statistics_data",
      pk: props.channelInfo.channel_pk,
      broad_seq: props.channelInfo.broad_seq,
      broad_status: props.channelInfo.broad_status,
    };

    const res = await channelApi.post(param);
    // console.log("statistic_data : ", res);
    if (res.result_code === "200") {
      setStatisticsList(res.result_body.statistic);
      setTotalLike(res.result_body.total_likes);
      setTotalView(res.result_body.total_views);
    }
  };

  const get_prod_click = async () => {
    const param: any = {
      command: "get_prod_click",
      broad_seq: props.channelInfo.broad_seq,
    };

    await channelApi.post(param).then((result: any) => {
      if (result.result_code === "200") {
        setProdClickList(result.result_body);
      } else {
        setProdClickList([]);
      }
    });
  };

  const get_livart_order_list = async () => {
    const param: any = {
      command: "get_livart_order_list",
      broad_seq: props.channelInfo.broad_seq,
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      const livartOrderList = res.response.livart_order_list.map((item: any, idx: number) => ({
        ...item,
        idx: idx,
      }));
      setLivartOrderList(livartOrderList);
      let _orderCount = 0;
      let _orderPrice = 0;
      let _cancelCount = 0;
      for (const orderInfo of res.response.livart_order_list) {
        if (orderInfo.status === "O") {
          // 주문
          _orderCount = _orderCount + parseInt(orderInfo.rmndQty); // 주문 상품수
          _orderPrice = _orderPrice + parseInt(orderInfo.payAmt); // 주문 금액
        } else {
          // 취소
          _cancelCount = _cancelCount + 1; // 취소 상품수(취소수량은 api에서 0으로 줘서 1로 변경)
        }
      }
      setLivartOrderPrice(_orderPrice);
      setLivartOrderCount(_orderCount);
      setLivartCancelCount(_cancelCount);
    }
  };

  const get_livart_cns_list = async () => {
    const param: any = {
      command: "get_livart_cns_list",
      broad_seq: props.channelInfo.broad_seq,
      live_type:
        props.channelInfo.host_id === "livartkitchen@livart.com"
          ? "02"
          : props.channelInfo.host_id === "livartmall@livart.com"
          ? "01"
          : "03",
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      setLivartCnsList(res.response.livart_cns_list);
      let _cnsCount = 0;
      for (const cnsInfo of res.response.livart_cns_list) {
        _cnsCount = _cnsCount + parseInt(cnsInfo.count);
      }
      setLivartCnsCount(_cnsCount);
    }
  };

  useEffect(() => {
    if (statisticsList.length > 0) {
      let newDataRows = [...statisticsList];

      const totalList = [];
      const concurentViewList = [];
      const enterCntList = [];
      const likeCntList = [];
      const prodCntList = [];
      const deliveredSecList = [];
      let lastConcurentView: any = undefined;
      let totalLiveViewTimeSec = 0;
      let totalLiveEnterCnt = 0;
      let totalLiveLikeCnt = 0;

      for (const data of newDataRows) {
        const time = dayjs(data.sk).format("YYYY-MM-DD HH:mm");
        totalList.push({
          time: time,
          concurrent: data.concurrent_cnt,
          enter: data.enter_cnt,
          like: data.like_cnt,
          diliveredTime: data.deliverd_sec,
        });
        concurentViewList.push({ label: data.sk, value: data.concurrent_cnt });
        enterCntList.push({ label: data.sk, value: data.enter_cnt });
        likeCntList.push({ label: data.sk, value: data.like_cnt });
        prodCntList.push({ label: data.sk, value: data.prod_click_cnt });
        deliveredSecList.push({ label: data.sk, value: data.deliverd_sec });
        lastConcurentView = data.concurrent_cnt;
        totalLiveViewTimeSec = totalLiveViewTimeSec + data.deliverd_sec;
        totalLiveEnterCnt = totalLiveEnterCnt + data.enter_cnt;
        totalLiveLikeCnt = totalLiveLikeCnt + data.like_cnt;
      }
      if (lastConcurentView !== undefined) {
        setConcurentView(lastConcurentView);
      }
      setTotalViewTime(totalLiveViewTimeSec);
      setTotalLiveView(totalLiveEnterCnt);
      setTotalLiveLike(totalLiveLikeCnt);

      setConcurentViewDataRows(concurentViewList);
      setEnterDataRows(enterCntList);
      setLikeDataRows(likeCntList);
      setProdDataRows(prodCntList);
      setDeliveredSecDataRows(deliveredSecList);
    }
  }, [statisticsList]);

  const delay: number = 60000; // 1분마다 변경
  useInterval(
    () => {
      get_statistics();
    },
    props.channelInfo.broad_status === "START" ? delay : null
  );

  const time: number = 1000;
  useInterval(
    () => {
      setBroadTime(convertTotalViewTime(cUtils.calPassedSec(props.channelInfo.broad_start_tm)));
    },
    props.channelInfo.broad_status === "START" ? time : null
  );

  const convertTotalViewTime = (totalSec: number) => {
    const t = cUtils.convertSecToTime(totalSec);
    return `${t.hours}:${t.minutes}:${t.seconds}`;
  };

  const downloadExcelLivartOrderList = () => {
    if (livartOrderList.length === 0) {
      toastRef.current?.toast("다운로드할 데이터가 없습니다.", "error", 3000);
      return;
    }
    // 컬럼 넓이
    const colWidth = [{ wpx: 40 }, { wpx: 150 }, { wpx: 150 }, { wpx: 100 }, { wpx: 300 }, { wpx: 100 }, { wpx: 100 }, { wpx: 150 }];
    // sheet header
    let orderList: any = [["순번", "주문일시", "주문번호", "상품번호", "상품명", "상태", "주문수량", "주문금액"]];
    // 각 행 순번 만들기
    let statisticsIndex = 1;
    // 내용 생성
    for (const item of livartOrderList) {
      let datetime = "";
      if (item.accpDtime !== "") {
        datetime = dayjs(item.accpDtime).format("YYYY-MM-DD HH:mm:ss");
      }
      const detail = [
        statisticsIndex,
        datetime,
        item.ordNo,
        item.goodsSn,
        item.goodsNm,
        item.status === "O" ? "주문" : "취소",
        cUtils.numericComma(Number(item.rmndQty)),
        cUtils.numericComma(Number(item.payAmt)),
      ];
      orderList = [...orderList, detail];
      statisticsIndex += 1;
    }

    const statsticsSheetName = "통계내역";
    // 파일명
    const fileName = `${props.channelInfo.broad_title} 주문 통계.xlsx`;
    cUtils.downloadExcel(orderList, colWidth, statsticsSheetName, fileName);
  };

  return (
    <>
      <Box>
        <Stack direction={"row"} spacing={2}>
          <ButtonGroup variant="outlined" fullWidth>
            {windowSize.width > 768 && (
              <Button>
                <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>라이브 보드</Typography>
              </Button>
            )}

            <Button
              onClick={() => {
                setOpenOrderDetail(true);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>
                  {cUtils.numericComma(livartOrderPrice)}원
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>결제금액</Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>(상세보기)</Typography>
                </Box>
              </Stack>
            </Button>
            <Button
              onClick={() => {
                // setViewChart(0);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>
                  {cUtils.numericComma(livartOrderCount)}건
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>결제 상품수</Typography>
              </Stack>
            </Button>
            <Button
              onClick={() => {
                // setViewChart(0);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>
                  {cUtils.numericComma(livartCancelCount)}건
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>취소 상품수</Typography>
              </Stack>
            </Button>
            <Button
              onClick={() => {
                // setViewChart(0);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>
                  {cUtils.numericComma(livartCnsCount)}건
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>상담 신청수</Typography>
              </Stack>
            </Button>

            <Button
              onClick={() => {
                // setViewChart(1);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "20px" : "14px", fontWeight: "700", color: "#000000" }}>
                  {windowSize.width > 768 ? `${cUtils.numericComma(totalLiveView)}명` : `${cUtils.numericComma(totalLiveView)}`}
                </Typography>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "12px" : "10px", fontWeight: "400", color: "#00000090" }}>
                  {windowSize.width > 768
                    ? `누적 시청자수 (VOD:${cUtils.numericComma(totalView - totalLiveView)}명)`
                    : `누적(V:${cUtils.numericComma(totalView - totalLiveView)})`}
                </Typography>
              </Stack>
            </Button>
            <Button
              onClick={() => {
                // setViewChart(0);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "20px" : "14px", fontWeight: "700", color: "#000000" }}>
                  {windowSize.width > 768 ? `${cUtils.numericComma(concurentView)}명` : `${cUtils.numericComma(concurentView)}`}
                </Typography>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "12px" : "10px", fontWeight: "400", color: "#00000090" }}>
                  {windowSize.width > 768 ? "동시 시청자수" : "동시"}
                </Typography>
              </Stack>
            </Button>
            <Button
              onClick={() => {
                // setViewChart(3);
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "20px" : "14px", fontWeight: "700", color: "#000000" }}>
                  {windowSize.width > 768
                    ? `${cUtils.numericComma(prodClickList.length)}회`
                    : `${cUtils.numericComma(prodClickList.length)}`}
                </Typography>
                <Typography sx={{ fontSize: windowSize.width > 768 ? "12px" : "10px", fontWeight: "400", color: "#00000090" }}>
                  {windowSize.width > 768 ? "상품 클릭수" : "상품"}
                </Typography>
              </Stack>
            </Button>
            {props.channelInfo.broad_status === "START" && (
              <Button
                onClick={() => {
                  // setViewChart(3);
                }}
              >
                <Stack direction={"column"} spacing={0}>
                  <Typography sx={{ fontSize: "20px", fontWeight: "700", color: "#000000" }}>{broadTime}</Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: "400", color: "#00000090" }}>라이브 진행시간</Typography>
                </Stack>
              </Button>
            )}
          </ButtonGroup>
        </Stack>
      </Box>
      <Dialog
        open={openOrderDetail}
        onClose={() => {
          setOpenOrderDetail(false);
        }}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", minWidth: "80%" } } }}
      >
        <DialogTitle>{"주문정보 상세"}</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", height: gridHeight }}>
            <DataGrid
              apiRef={gridApi}
              rows={livartOrderList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: pageSize,
                  },
                },
                sorting: {
                  sortModel: [
                    {
                      field: "range_num",
                      sort: "asc",
                    },
                  ],
                },
              }}
              slots={{ pagination: CustomPagination }}
              hideFooterSelectedRowCount
              getRowId={(row) => row.ordNo + row.idx}
              rowSelectionModel={selectedRow}
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRow(newRowSelectionModel);
              }}
              paginationModel={selectedPage}
              onPaginationModelChange={(newPagenationModel) => {
                setSelectedPage(newPagenationModel);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              downloadExcelLivartOrderList();
            }}
          >
            엑셀 다운로드
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenOrderDetail(false);
            }}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      <Toast ref={toastRef} />
    </>
  );
};

export default LiveStatistic;
