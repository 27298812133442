import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWindowSize } from "../../../utils/useWindowSize";

import {
  Box,
  Stack,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { HttpAdminApi } from "../../../interface/admin-api";
import { HttpChannelApi } from "../../../interface/channel-api";
import { CommonUtils } from "../../../utils/common_utils";

import { userState } from "../../../interface/MainInterface";

interface propsType {
  userState: userState;
  channelInfo: any;
  callback: any;
}

const adminApi = new HttpAdminApi();
const channelApi = new HttpChannelApi();
const cUtils = new CommonUtils();

const ChannelManageModule = (props: propsType) => {
  const windowSize = useWindowSize();
  const [openBroadcastInfo, setOpenBroadcastInfo] = useState(false);

  const [password, setPassword] = useState("");
  useEffect(() => {
    setOpenBroadcastInfo(false);
    return () => {
      setPassword("");
    };
  }, []);

  useEffect(() => {
    if (!cUtils.isEmptyObj(props.channelInfo)) setPassword(props.channelInfo?.password);
  }, [props.channelInfo]);

  const setIsLoading = (value: boolean) => {
    props.callback({ command: "loading", value: value });
  };

  const toast = (msg: string, type: string, sec: number) => {
    props.callback({ command: "toast", msg: msg, type: type, sec: sec });
  };

  const check_stream = async (cmd: string) => {
    // 방송 스트림 상태 조회
    let check_stream = true;
    if (cmd === "start_stream") {
      const streamParam: any = {
        command: "get_stream_with_status",
        channel_info: {
          channel_arn: props.channelInfo.ivs_channel_arn,
        },
        status: cmd,
      };
      await adminApi.post(streamParam).then((res) => {
        if (res.code !== "200") check_stream = false;
        if (cUtils.isEmptyObj(res.response.stream_info)) check_stream = false;
      });
    }

    if (cmd === "start_stream" && !check_stream && !props.userState.isSuperAdmin) {
      alert("방송 시작전 영상을 먼저 송출하세요.");
      setIsLoading(false);
      return false;
    } else {
      return true;
    }
  };

  // 방송상태 변경
  const handleStream = async (status: string) => {
    // 권한
    if (!props.userState.isSuperAdmin && props.userState.id !== props.channelInfo.host_id) {
      alert("다른 사람의 채널을 수정하실 수 없습니다.");
      return;
    }
    let cmd = "";
    if (status === "START") cmd = "start_stream";
    else if (status === "READY") cmd = "ready_stream";
    else if (status === "STOP") cmd = "stop_stream";

    setIsLoading(true);
    const canChange = await check_stream(cmd);

    if (canChange) {
      const param: any = {
        command: cmd,
        broad_info: {
          pk: props.channelInfo.channel_pk,
          broad_seq: props.channelInfo.broad_seq,
        },
        request_user_id: props.userState.id,
      };

      const res = await channelApi.post(param);
      if (res.result_code === "200") {
        props.callback({ command: "update_stream", value: cmd });
      } else {
        alert(`[ERROR] ${res.result_body}`);
      }
    }
    setIsLoading(false);
  };

  // 채널 패스워드 변경
  const set_channel_password = async () => {
    if (!window.confirm("비밀번호를 변경하시겠습니까?")) return;

    const param: any = {
      command: "set_channel_password",
      channel_pk: props.channelInfo.channel_pk,
      broad_seq: props.channelInfo.broad_seq,
      password: password,
    };

    const res = await adminApi.post(param);
    if (res.code === "200") {
      props.callback({ command: "change_password" });
    }
  };

  return (
    <>
      <Box>
        <Stack direction={windowSize.width > 768 ? "row" : "column"} spacing={windowSize.width > 768 ? 2 : 1}>
          {/* 방송 상태 변경 */}
          <ToggleButtonGroup
            color="standard"
            value={props.channelInfo.broad_status}
            fullWidth
            sx={{ maxWidth: "500px" }}
            size="small"
            exclusive
            onChange={(event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
              if (cUtils.isEmptyObj(props.channelInfo)) return false;
              switch (newAlignment) {
                case "READY":
                  if (window.confirm("방송을 준비중 상태로 변경하시겠습니까?")) {
                    handleStream(newAlignment);
                  }
                  break;
                case "START":
                  if (window.confirm("방송을 시작 하시겠습니까?")) {
                    handleStream(newAlignment);
                  }
                  break;
                case "STOP":
                  if (window.confirm("방송을 중지 하시겠습니까?")) {
                    handleStream(newAlignment);
                  }
                  break;
                default:
                  break;
              }
            }}
            aria-label="방송상태"
            disabled={!props.userState.isSuperAdmin && props.userState.id !== props.channelInfo.host_id}
          >
            <ToggleButton value="CREATE" color="secondary">
              생성
            </ToggleButton>
            <ToggleButton value="READY" color="secondary">
              준비중
            </ToggleButton>
            <ToggleButton value="START" color="error">
              방송중
            </ToggleButton>
            <ToggleButton value="STOP" color="warning">
              종료
            </ToggleButton>
            <ToggleButton value="VOD" color="primary">
              VOD
            </ToggleButton>
          </ToggleButtonGroup>
          {/* 송출정보 복사 */}
          <ButtonGroup variant="outlined" fullWidth sx={windowSize.width > 768 ? { maxWidth: 360 } : {}}>
            <Button
              onClick={() => {
                setOpenBroadcastInfo(true);
              }}
            >
              송출 정보
            </Button>
            <CopyToClipboard
              text={`rtmps://${props.channelInfo.ivs_ingest_endpoint}:443/app/`}
              onCopy={() => {
                toast("클립보드에 복사가 완료되었습니다.", "success", 3000);
              }}
            >
              <Button endIcon={<ContentCopyIcon />}>서버</Button>
            </CopyToClipboard>
            <CopyToClipboard
              text={props.channelInfo.ivs_stream_key}
              onCopy={() => {
                toast("클립보드에 복사가 완료되었습니다.", "success", 3000);
              }}
            >
              <Button endIcon={<ContentCopyIcon />}>KEY</Button>
            </CopyToClipboard>
          </ButtonGroup>
          {/* 라이브 미리보기 */}
          <ButtonGroup variant="outlined" fullWidth sx={windowSize.width > 768 ? { maxWidth: 240 } : {}}>
            <Button
              onClick={() => {
                window.open(`${process.env.REACT_APP_PLAYER_DOMAIN}/stream/${props.channelInfo.broad_seq}?livartBroadSeq=${props.channelInfo.livart_broad_seq}`);
              }}
              endIcon={<SearchIcon />}
            >
              라이브
            </Button>
            <Button
              onClick={() => window.open(`${process.env.REACT_APP_PLAYER_DOMAIN}/frame/${props.channelInfo.broad_seq}?mode=rehearsal&livartBroadSeq=${props.channelInfo.livart_broad_seq}`)}
              endIcon={<SearchIcon />}
            >
              리허설
            </Button>
          </ButtonGroup>
          {/* 비밀번호 설정 */}
          <Stack direction={"row"} spacing={windowSize.width > 768 ? 0 : 1}>
            <TextField
              id="txtPassword"
              label="비밀번호"
              variant="outlined"
              size="small"
              value={password}
              type="search"
              autoComplete="off"
              inputProps={{ enterKeyHint: "Enter" }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Button variant="contained" sx={{ ml: 1 }} onClick={set_channel_password}>
              변경
            </Button>
          </Stack>
          {/* 링크 복사 */}
          <ButtonGroup variant="contained" fullWidth sx={windowSize.width > 768 ? { maxWidth: 240 } : {}}>
            <CopyToClipboard
              text={`${process.env.REACT_APP_PLAYER_DOMAIN}/stream/${props.channelInfo.broad_seq}?livartBroadSeq=${props.channelInfo.livart_broad_seq}`}
              onCopy={() => {
                toast(`외부광고용 링크 복사: ${process.env.REACT_APP_PLAYER_DOMAIN}/stream/${props.channelInfo.broad_seq}?livartBroadSeq=${props.channelInfo.livart_broad_seq}`, "success", 3000);
              }}>
              <Button endIcon={<ContentCopyIcon />}>외부광고용</Button>
            </CopyToClipboard>
            <CopyToClipboard
              text={`${process.env.REACT_APP_LIVART_VIEWER_DOMAIN}/${props.channelInfo.livart_broad_seq}/${props.channelInfo.broad_seq}`}
              onCopy={() => {
                toast(`통합몰용 링크 복사 ${process.env.REACT_APP_LIVART_VIEWER_DOMAIN}/${props.channelInfo.livart_broad_seq}/${props.channelInfo.broad_seq}`, "success", 3000);
              }}>
              <Button endIcon={<ContentCopyIcon />}>통합몰용</Button>
            </CopyToClipboard>
          </ButtonGroup>
        </Stack>
        <Dialog
          open={openBroadcastInfo}
          onClose={() => {
            setOpenBroadcastInfo(false);
          }}
          sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%", minWidth: "80%" } } }}
        >
          <DialogTitle>{"방송정보 상세"}</DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", height: 608 }}>
              <TextField
                id="txtServer"
                label="서버"
                variant="outlined"
                size="small"
                value={`rtmps://${props.channelInfo.ivs_ingest_endpoint}:443/app/`}
                type="search"
                fullWidth
                sx={{ mt: 1 }}
              />
              <TextField
                id="txtStreamKey"
                label="스트림 키"
                variant="outlined"
                size="small"
                value={props.channelInfo.ivs_stream_key}
                type="search"
                fullWidth
                sx={{ mt: 1 }}
              />
              <Typography sx={{ mt: 1 }}>[OBS Studio 샘플 예시]</Typography>
              <Box component="img" sx={{ mt: 1, width: "100%" }} src="/images/img-broadcast-info.png" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpenBroadcastInfo(false);
              }}
            >
              닫기
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ChannelManageModule;
